import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TabActivationService } from '@shared/services/tab-activation.service';

@Injectable()
export class TabIdInterceptor implements HttpInterceptor {
  constructor(private tabActivationService: TabActivationService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedReq = req.clone({
      headers: req.headers.set('tab-id', this.tabActivationService.tabId),
    });
    return next.handle(modifiedReq);
  }
}
