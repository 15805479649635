import { Routes } from '@angular/router';
import { ERRORS_ROUTE, ORGANIZATION_DETAIL_ROUTE, PROJECTS_ROUTE, SETTINGS_ROUTE } from './app.routes';

// TODO: неудачная структура. Нужно декомпозировать каждый маршрут
// для того чтобы иметь возможность четко определять текущую страницу для
// последующих перенаправлений, а так же для повышения читабельности кода, убирая вложенность маршрутов.
// Использовать пример PROJECTS_ROUTE. Посмотреть нет ли лишних зависимостей как было с report layout
export const APP_ROUTES: Routes = [
  {
    path: 'invite/accept',
    loadComponent: () =>
      import('./core/containers/organization-accept-invite.component').then((m) => m.OrganizationAcceptInviteComponent),
  },
  {
    path: 'signin-callback',
    loadComponent: () => import('./core/containers/sign-in-callback.component').then((m) => m.SignInCallbackComponent),
  },
  {
    path: 'signin-organization-callback',
    loadComponent: () =>
      import('./core/containers/sign-in-organization-callback.component').then(
        (m) => m.SignInOrganizationCallbackComponent
      ),
  },
  ERRORS_ROUTE,
  SETTINGS_ROUTE,
  PROJECTS_ROUTE,
  ORGANIZATION_DETAIL_ROUTE,
  {
    path: '**',
    redirectTo: 'settings/files',
  },
];
