import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, UnrecoverableStateEvent, VersionEvent } from '@angular/service-worker';

import { interval, merge, Observable, Subject } from 'rxjs';
import { first, takeUntil, tap, filter, exhaustMap, take, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApplicationUpdateService {
  private stopWatch$: Subject<void> = new Subject<void>();
  private readonly checkUpdateInterval = 30 * 1000; // 30 sec
  private readonly unrecoverableChanges$: Subject<UnrecoverableStateEvent> = new Subject<UnrecoverableStateEvent>();
  private readonly maxAppIsStableTimeout = 60000;

  public updateAvailable = false;

  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate
  ) {}

  /**
   * Periodically watch for new updates
   * after deploy new bundles. Could be stopped
   * by {@link stopWatchingOnApplicationUpdates}
   */
  public watchApplicationUpdates(): void {
    if (!environment.productionMode) {
      return;
    }
    this.stopWatchingOnApplicationUpdates();

    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const checkInterval$ = interval(this.checkUpdateInterval);

    merge(appIsStable$, interval(this.maxAppIsStableTimeout).pipe(take(1)))
      .pipe(
        exhaustMap(() => checkInterval$),
        takeUntil(this.stopWatch$)
      )
      .subscribe(() => {
        console.log(`✎: [applicaiton-update.service.ts][${new Date().toString()}] check updates!`);
        this.updates.checkForUpdate();
      });
  }

  /**
   * Watch for new updates loaded
   */
  public watchAvaiableUpdates(): Observable<VersionEvent> {
    return this.updates.versionUpdates.pipe(
      tap((e) => console.log(`✎: [applicaiton-update.service.ts][${new Date().toString()}] version update event: `, e)),
      filter((event) => event.type === 'VERSION_READY'),
      tap(() => {
        this.updateAvailable = true;
      })
    );
  }

  /**
   * Register error about incompatible changes
   */
  public registerUnrecoverableChanges(reason: string): void {
    this.unrecoverableChanges$.next({ type: 'UNRECOVERABLE_STATE', reason });
  }

  /**
   * Watch for broken build!
   */
  public watchUnrecoverableUpdates(): Observable<UnrecoverableStateEvent> {
    return merge(this.updates.unrecoverable, this.unrecoverableChanges$);
  }

  public watchInstallationFailed(): Observable<VersionEvent> {
    return this.updates.versionUpdates.pipe(
      distinctUntilChanged((prev, curr) => prev.type === curr.type),
      filter((event) => event.type === 'VERSION_INSTALLATION_FAILED'),
      tap((event) => {
        console.warn('[SW: installation failed event detected: ', event);
      })
    );
  }

  /**
   * Apply last update if exist.
   */
  public async applyUpdate(): Promise<void> {
    if (!this.updateAvailable) {
      return;
    }
    await this.updates.activateUpdate();
    this.updateAvailable = false;
  }

  public async forceApplyUpdate(): Promise<void> {
    await this.updates.activateUpdate();
    this.updateAvailable = false;
  }

  /**
   * Unsubscribing from new bundle updates.
   */
  public stopWatchingOnApplicationUpdates(): void {
    this.stopWatch$.next();
  }
}
