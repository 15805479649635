import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';
import { enablePatches, setAutoFreeze } from 'immer';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsDataPluginModule } from '@angular-ru/ngxs';
import { NGXS_DATA_STORAGE_PLUGIN, NGXS_DATA_STORAGE_PREFIX_TOKEN } from '@angular-ru/ngxs/storage';
import { AsyncStoragePluginModule } from './plugins/async-storage-plugin';
import { LocalizationPlatformsState } from '@store/localization-platforms-store';
import { AvailableLanguagesState } from './available-languages';
import { ReportState } from './report-store/report.state';
import { UploadingFilesState } from '@store/uploading-files';
import { StateClass } from '@ngxs/store/internals';
import { OrganizationsState } from './organizations-store/organizations.state';
import { PotentialUntranslatablesState } from '@store/potential-untranslatables';
import { environment } from 'src/environments/environment';
import { ProjectsCollaboratorsState } from './projects-collaborators/projects-collaborators.state';
import { ProjectEffects } from './project-store/project.effects';
import { ngxsConfig } from '@core/constants';
import { QualityIssueDAO } from '@core/infrastructure/repositories';
import { SystemDAO } from '@core/infrastructure/repositories/system.repository';
import { SpecialSymbolsState } from '@shared/components/special-symbols/special-symbols.state';
import { AuthState } from './auth/auth.state';
import { CommonReportViewState } from './common-report-view-store';
import { GlossaryListState } from './glossary-list-store';
import { GlossaryTermsState } from './glossary-terms-store';
import { LanguageState } from './language-store';
import { LocaleQASettingsState } from './locale-qa-settings-store';
import { PaymentState } from './payment-store';
import { ProjectFilesState } from './project-files-store';
import { ProjectState } from './project-store';
import { ProjectsState } from './projects-store/projects.state';
import { QASettingsMapper, QASettingsState } from './qa-settings-store';
import { ReportViewState } from './report-view-store';
import { SystemState } from './system';
import { QASettingsTemplatesState } from './template-list-store';
import { UserDictionariesState } from './user-dictionaries-store';
import { SearchInProjectFilesResultItemMapper } from '@core/mappers/search-in-project-files-result-item.mapper';
import { TranslationUnitMapper } from '@core/mappers/translation-unit.mapper';
import { BlobDownloadService } from '@shared/services/blob-download.service';
import { TagsValidationService } from '@shared/services/tags-validation.service';
import { QualityIssueMapper } from '@core/mappers/quality-issue.maper';
import { SegmentMapper } from '@core/mappers/segment.mapper';
import { RenderItemBuilder } from '@shared/services/render-item-builder.service';
import { ConsistencyTransactionState } from '@features/report/store/consistency-subgroup-transaction-store/consistency-transaction.state';
import { QualityIssueKindsState } from './quality-issue-kinds-store/quality-issue-kinds.state';
import { STORAGE_NAME_PREFIX } from '@core/constants/storage-name-prefix.constant';
import { OnboardingState } from '@features/onbording/onboarding.state';

enablePatches();
setAutoFreeze(false);

@NgModule({
  imports: [
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.productionMode,
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: true, // environment.production,
    }),
    NgxsDataPluginModule.forRoot([NGXS_DATA_STORAGE_PLUGIN]),
    AsyncStoragePluginModule,
    NgxsModule.forRoot(
      [
        ProjectsState,
        AuthState,
        ProjectState,
        ProjectFilesState,
        QASettingsTemplatesState,
        QASettingsState,
        LocaleQASettingsState,
        GlossaryListState,
        GlossaryTermsState,
        ReportViewState,
        ReportState,
        LanguageState,
        LocalizationPlatformsState,
        AvailableLanguagesState,
        UploadingFilesState,
        CommonReportViewState,
        SpecialSymbolsState,
        OrganizationsState,
        PotentialUntranslatablesState,
        SystemState,
        PaymentState,
        ConsistencyTransactionState,
        ProjectsCollaboratorsState,
        UserDictionariesState,
        QualityIssueKindsState,
        OnboardingState,
      ] as StateClass[],
      ngxsConfig
    ),
  ],
  providers: [
    { provide: NGXS_DATA_STORAGE_PREFIX_TOKEN, useValue: STORAGE_NAME_PREFIX },
    TagsValidationService,
    QualityIssueMapper,
    SearchInProjectFilesResultItemMapper,
    QASettingsMapper,
    BlobDownloadService,
    TranslationUnitMapper,
    QualityIssueDAO,
    SystemDAO,
    ProjectEffects,
    SegmentMapper,
    RenderItemBuilder,
  ],
})
export class StoreModule {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(_: ProjectEffects) {}
}
