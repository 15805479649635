import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  ErrorInterceptor,
  OfflineInterceptor,
  RetryInterceptor,
  RetryInterceptorConfigs,
  RETRY_INTERCEPTOR_CONFIGS,
  TokenAwaitInterceptor,
} from './interceptors';
import { StoreModule } from '@store/store.module';
import { TabIdInterceptor } from './interceptors/tab-id.interceptor';
import { TabActivationService } from '@shared/services/tab-activation.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TabIdService } from './services/tab-id.service';
import { ApiConfiguration, HttpApiModule } from '@api';
import { ApiConfiguration as PaymentApiConfiguration } from '@payment-api';
import { HttpPaymentApiModule } from '@payment-api';
import { MainErrorHandler } from './services/main-error-handler.service';
import { SidebarService } from '@features/report/components/sidebar/sidebar.service';
import { LocalStorageService } from './services/local-storage.service';
import { NotificationModule } from '@features/notification';
import { CommonHubService } from '@shared/services/common-hub.service';
import { TranslationService } from './services/translation.service';
import { AppService } from './services/app.service';
import { ApplicationUpdateService } from './services/applicaiton-update.service';
import { FaviconService } from './services/favicon.service';
import { MultiTabService } from './services/multi-tab.service';
import { SharedWorkerService } from './services/shared-worker-client.service';
import { ShortcutsService } from './services/shortcuts';
import { NavigatorService } from './services/navigator.service';
import { StorageConfig } from './models/storage-config.model';
import { AuthModule, RegisterGuardService, RegisteredGuard } from '@features/auth';
import { LoggerModule } from '@features/logger';
import { GoogleAnalyticsModule } from '@features/google-analytics';
import { sessionStorageConfig } from './constants/session-storage.constant';
import { SessionStorageService } from './services/session-storage.service';
import { environment } from 'src/environments/environment';
import { BrowserCacheService } from './services/browser-cache.service';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

const retryInterceptorConfigs: RetryInterceptorConfigs = {
  ignorePaths: [/https:\/\/auth-dev\.e-verifika\.com/],
};

@NgModule({
  imports: [
    StoreModule,
    AuthModule,
    LoggerModule,
    GoogleAnalyticsModule,
    HttpApiModule.forRoot({}),
    HttpPaymentApiModule.forRoot({}),
    NotificationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    TabIdService,
    AppService,
    TabActivationService,
    MultiTabService,
    { provide: ErrorHandler, useClass: MainErrorHandler },
    ApplicationUpdateService,
    RegisterGuardService,
    RegisteredGuard,
    CommonHubService,
    TranslationService,
    NavigatorService,
    ShortcutsService,
    BrowserCacheService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {
  public static forRoot(config: StorageConfig): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ApplicationUpdateService,
        FaviconService,
        SharedWorkerService,
        SidebarService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenAwaitInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true },
        { provide: RETRY_INTERCEPTOR_CONFIGS, useValue: retryInterceptorConfigs, multi: false },
        { provide: HTTP_INTERCEPTORS, useClass: TabIdInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
        { provide: ErrorHandler, useClass: MainErrorHandler },
        { provide: LocalStorageService, useValue: new LocalStorageService(config) },
        { provide: SessionStorageService, useValue: new SessionStorageService(sessionStorageConfig) },
        {
          provide: ApiConfiguration,
          useValue: {
            rootUrl: environment.apiUrl ?? '',
          },
        },
        {
          provide: PaymentApiConfiguration,
          useValue: {
            rootUrl: `${environment.apiUrl ?? ''}/payment`,
          },
        },
      ],
    };
  }
  constructor(@Optional() @SkipSelf() core: CoreModule, translate: TranslateService) {
    if (core) {
      throw new Error('You should import core module only in the root module');
    }
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
