import { Injectable } from '@angular/core';
import { Observable, forkJoin, from, of, switchMap } from 'rxjs';

@Injectable()
export class BrowserCacheService {
  /*
   * Remove all cached data from the browser storage cache
   */
  public reset(): Observable<unknown> {
    if (!caches) {
      return of(null);
    }
    return from(caches.keys()).pipe(
      switchMap((keys) => (keys.length ? forkJoin(keys.map((k) => from(caches.delete(k)))) : of(null)))
    );
  }
}
