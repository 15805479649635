import { enableProdMode, importProvidersFrom } from '@angular/core';
import { LicenseManager } from '@ag-grid-enterprise/core';

import { prepareStore } from '@store/plugins/async-storage-plugin';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { AppAnimationsModule } from './app/app-animations.module';
import { APP_ROUTES } from './app/app.routing';
import { CoreModule } from './app/core/core.module';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { SwRegistrationOptions, ServiceWorkerModule } from '@angular/service-worker';
import { localStorageConfig } from '@core/constants/local-storage-config.constant';
import { AppConfig } from '@core/constants/app-config.constant';
import {
  PreloadAllModules,
  provideRouter,
  withPreloading,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { ApplicationUpdateService } from '@core/services/applicaiton-update.service';
import { TabIdService } from '@core/services/tab-id.service';
import { browserTracingIntegration, init, replayIntegration } from '@sentry/angular';

function Run(): void {
  Promise.all([TabIdService.init(), loadConfig(), prepareStore()]).then(([, environmentConfig]) => {
    console.log('✎: [line 14][main.ts] environmentConfig: ', environmentConfig);
    Object.keys(environmentConfig).forEach((key) => {
      environment[key] = environmentConfig[key];
    });
    if (environment.productionMode) {
      enableProdMode();
    }
    if (environment.enableSentry) {
      initSentry();
    }
    registerAgGrid();

    bootstrapApplication(AppComponent, {
      providers: [
        provideRouter(
          APP_ROUTES,
          withPreloading(PreloadAllModules),
          // withDebugTracing(),
          withEnabledBlockingInitialNavigation()
        ),
        importProvidersFrom(
          CoreModule.forRoot(localStorageConfig),
          AppAnimationsModule,
          ServiceWorkerModule.register('ngsw-worker.js')
        ),
        ApplicationUpdateService,
        {
          provide: SwRegistrationOptions,
          useFactory: (): SwRegistrationOptions => ({
            enabled: environment.productionMode,
            registrationStrategy: 'registerWithDelay: 5000',
          }),
        },
        provideHttpClient(withInterceptorsFromDi()),
      ],
    }).catch((err) => console.error(err));
  });
}
Run();

async function loadConfig(): Promise<Record<string, AppConfig>> {
  const res = await fetch(`assets/config/${environment.configFile}`);
  return await res.json();
}

function initSentry(): void {
  init({
    dsn: environment.sentryDsn,
    maxBreadcrumbs: 30,
    autoSessionTracking: true,
    integrations: [browserTracingIntegration(), replayIntegration()],
    release: environment.releaseName,

    tracesSampleRate: environment.sentryTracesRate,
    replaysOnErrorSampleRate: environment.sentryReplaysErrorsRate,
    replaysSessionSampleRate: environment.sentryReplaysSessionRate,
  });
}

function registerAgGrid(): void {
  LicenseManager.setLicenseKey(environment.agGridLicenseKey);
}
